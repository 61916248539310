.home-landing {
  background-image: url("../../assets/bg.png");
  min-height: 100vh;
  background-position: center;
  background-size: cover;
}

.home-landing .home-landing-text {
  margin-top: 18vh;
}
.home-landing .tagline {
  color: #fff;
  font-size: 62px;
  font-family: "Roboto";
  font-weight: 500;
  text-shadow: 0.1em 0.1em 0.2em rgb(0 0 0 / 60%);
}

.home-landing .btn-home-practise {
  width: 200px !important;
  background-color: #fff;
  color: #000;
  font-family: "Roboto";
  border-radius: 25px;
  border: none !important;
  margin-right: 25px;
}

.home-landing .btn-home-contact {
  width: 200px !important;
  background-color: #fff;
  color: #000;
  font-family: "Roboto";
  border-radius: 25px;
}

@media all and (min-width: 0px) and (max-width: 422px) {
  .home-landing {
    background-image: url("../../assets/bg.png");
    height: 379px;
    background-position: center;
    background-size: cover;
  }

  .home-landing .tagline {
    line-height: 4rem;
    text-align: center;
    font-size: 32px;
  }

  .home-landing .mobile-center {
    text-align: center;
  }

  .home-landing .landing-btn {
    width: 270px;
    margin-bottom: 16px;
  }

  .home-landing .btn-home-practise {
    margin-right: 0;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .home-landing .btn-home-practise {
    margin-bottom: 16px;
  }
}
