.area-of-practise-header {
  max-width: 100%;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  margin-bottom: 2rem;
}

.area-of-practise-header-title {
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
