.about-page p {
  font-family: "Roboto";
  font-size: 18px;
  text-align: justify;
}

.about-page .about-image {
  border-radius: 25px;
}

.about-page .about-headline {
  font-size: 28px;
  font-weight: 500;
}

@media all and (max-width: 480px) {
  .about-page .about-headline {
    padding-top: 8px;
  }

  .about-page p {
    text-align: left;
  }
}
