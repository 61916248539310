.home-area-of-practise {
  background-color: #dce2ed;
  padding: 40px 0;
}

.home-area-of-practise .areas-of-practise-header {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
}
