p {
  font-family: "Open Sans";
  font-size: 17px;
}

.areas-of-practise-title {
  font-size: 24px;
  font-weight: 600;
}

.min-view {
  height: 100%;
}
