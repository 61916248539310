* {
  font-family: "Open Sans", sans-serif;
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #232323;
}

::-webkit-scrollbar-thumb {
  background: #464646;
}

.min-view {
  min-height: 70vh !important;
}

@media screen and (orientation: landscape) {
  .min-view {
    height: 100%;
  }
}
