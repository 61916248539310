.contact-form {
  padding: 90px 0;
}

.contact-form .map {
  border-radius: 25px;
}

.contact-form .form-control {
  border-radius: 10px !important;
  background-color: #f5f8fd !important;
  border: none;
}

.contact-form .btn-form {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #0095ff !important;
  border: none !important;
  border-radius: 25px !important;
  width: 180px;
  font-family: "Open Sans";
  font-size: 16px;
  text-align: center;
}

.contact-form .map-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 25px;
}

.contact-form .map-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media all and (max-width: 480px) {
  .min-view {
    height: 100%;
  }
}
