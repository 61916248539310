.home-about {
  padding: 40px 0;
}

.home-about p {
  font-family: "Roboto";
  font-size: 18px;
  text-align: left;
}

.home-about .home-about-headline {
  font-weight: 500;
  font-size: 22px;
}

.home-about .btn-home-about-custom {
  background-color: #4a6276;
  width: 100%;
  border-radius: 25px;
  height: 303px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-about .btn-home-about-custom-text {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
}

.home-about .about-img {
  border-radius: 25px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
  .home-about .btn-home-about-custom {
    height: 251.66px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1280px) {
  .home-about .btn-home-about-custom {
    height: 302px;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .home-about .btn-home-about-custom {
    height: 251.66px;
  }
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .home-about .btn-home-about-custom {
    height: 184.55px;
  }
}

@media all and (min-width: 0px) and (max-width: 325px) {
  .home-about .btn-home-about-custom {
    margin-top: 1rem;
    height: 162.17px;
  }
}

@media all and (min-width: 325px) and (max-width: 415px) {
  .home-about .btn-home-about-custom {
    margin-top: 1rem;
    height: 214px;
  }
}

@media all and (min-width: 415px) and (max-width: 575px) {
  .home-about .btn-home-about-custom {
    margin-top: 1rem;
    height: 300px;
  }
}

@media all and (min-width: 575px) and (max-width: 767px) {
  .home-about .btn-home-about-custom {
    margin-top: 1rem;
    height: 300px;
  }
}
