@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

/* Nav Bar Start */
.light-nav .navbar-light .navbar-nav .nav-link {
  font-family: "Roboto" !important;
  font-size: 20px;
  color: #fff !important;

  padding-right: 1rem !important;
  padding-left: 1rem !important;
  -webkit-touch-callout: none;
}

.navbar-light .navbar-brand {
  font-family: "Roboto" !important;
  font-size: 24px;
  color: #000 !important;
  font-weight: 500;
}

.navbar-light {
  padding-right: 50px !important;
  padding-left: 50px !important;
  padding-top: 15px !important;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.home-landing .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  -webkit-touch-callout: none;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  font-family: "Open Sans";
  font-weight: 400;
}

.dropdown-content a:hover {
  background-color: #ddd !important;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.light-nav .nav-contact {
  border: 2px solid #fff;
  border-radius: 25px;
}
/* Nav Bar End */

@media only screen and (max-width: 768px) {
  .navbar-light .navbar-brand {
    font-size: 14px;
  }

  .navbar-light {
    padding-right: 15px !important ;
    padding-left: 15px !important ;
  }

  .nav-contact {
    border: 2px solid #fff;
    border-radius: 25px;
  }

  .navbar-light .brand-img {
    width: 200px !important;
  }
}
