@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
* {
  font-family: "Open Sans", sans-serif;
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #232323;
}

::-webkit-scrollbar-thumb {
  background: #464646;
}

.min-view {
  min-height: 70vh !important;
}

@media screen and (orientation: landscape) {
  .min-view {
    height: 100%;
  }
}

.footer {
  background-color: #171c26;
  padding: 40px 0;
  color: #fff;
}

.footer .social {
  width: 44px;
  margin-right: 8px;
}

.footer a {
  color: #0095ff;
  text-decoration: none !important;
}

.footer a:hover {
  color: #0095ff;
  text-decoration: none !important;
}

.footer .footer-title {
  font-weight: 600;
  font-size: 20px;
}

.footer .copyright {
  font-size: 15px;
}


.home-landing {
  background-image: url(/static/media/bg.86e36a23.png);
  min-height: 100vh;
  background-position: center;
  background-size: cover;
}

.home-landing .home-landing-text {
  margin-top: 18vh;
}
.home-landing .tagline {
  color: #fff;
  font-size: 62px;
  font-family: "Roboto";
  font-weight: 500;
  text-shadow: 0.1em 0.1em 0.2em rgb(0 0 0 / 60%);
}

.home-landing .btn-home-practise {
  width: 200px !important;
  background-color: #fff;
  color: #000;
  font-family: "Roboto";
  border-radius: 25px;
  border: none !important;
  margin-right: 25px;
}

.home-landing .btn-home-contact {
  width: 200px !important;
  background-color: #fff;
  color: #000;
  font-family: "Roboto";
  border-radius: 25px;
}

@media all and (min-width: 0px) and (max-width: 422px) {
  .home-landing {
    background-image: url(/static/media/bg.86e36a23.png);
    height: 379px;
    background-position: center;
    background-size: cover;
  }

  .home-landing .tagline {
    line-height: 4rem;
    text-align: center;
    font-size: 32px;
  }

  .home-landing .mobile-center {
    text-align: center;
  }

  .home-landing .landing-btn {
    width: 270px;
    margin-bottom: 16px;
  }

  .home-landing .btn-home-practise {
    margin-right: 0;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .home-landing .btn-home-practise {
    margin-bottom: 16px;
  }
}

/* Nav Bar Start */
.light-nav .navbar-light .navbar-nav .nav-link {
  font-family: "Roboto" !important;
  font-size: 20px;
  color: #fff !important;

  padding-right: 1rem !important;
  padding-left: 1rem !important;
  -webkit-touch-callout: none;
}

.navbar-light .navbar-brand {
  font-family: "Roboto" !important;
  font-size: 24px;
  color: #000 !important;
  font-weight: 500;
}

.navbar-light {
  padding-right: 50px !important;
  padding-left: 50px !important;
  padding-top: 15px !important;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.home-landing .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  -webkit-touch-callout: none;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  font-family: "Open Sans";
  font-weight: 400;
}

.dropdown-content a:hover {
  background-color: #ddd !important;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.light-nav .nav-contact {
  border: 2px solid #fff;
  border-radius: 25px;
}
/* Nav Bar End */

@media only screen and (max-width: 768px) {
  .navbar-light .navbar-brand {
    font-size: 14px;
  }

  .navbar-light {
    padding-right: 15px !important ;
    padding-left: 15px !important ;
  }

  .nav-contact {
    border: 2px solid #fff;
    border-radius: 25px;
  }

  .navbar-light .brand-img {
    width: 200px !important;
  }
}

.home-about {
  padding: 40px 0;
}

.home-about p {
  font-family: "Roboto";
  font-size: 18px;
  text-align: left;
}

.home-about .home-about-headline {
  font-weight: 500;
  font-size: 22px;
}

.home-about .btn-home-about-custom {
  background-color: #4a6276;
  width: 100%;
  border-radius: 25px;
  height: 303px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-about .btn-home-about-custom-text {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
}

.home-about .about-img {
  border-radius: 25px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
  .home-about .btn-home-about-custom {
    height: 251.66px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1280px) {
  .home-about .btn-home-about-custom {
    height: 302px;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .home-about .btn-home-about-custom {
    height: 251.66px;
  }
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .home-about .btn-home-about-custom {
    height: 184.55px;
  }
}

@media all and (min-width: 0px) and (max-width: 325px) {
  .home-about .btn-home-about-custom {
    margin-top: 1rem;
    height: 162.17px;
  }
}

@media all and (min-width: 325px) and (max-width: 415px) {
  .home-about .btn-home-about-custom {
    margin-top: 1rem;
    height: 214px;
  }
}

@media all and (min-width: 415px) and (max-width: 575px) {
  .home-about .btn-home-about-custom {
    margin-top: 1rem;
    height: 300px;
  }
}

@media all and (min-width: 575px) and (max-width: 767px) {
  .home-about .btn-home-about-custom {
    margin-top: 1rem;
    height: 300px;
  }
}

.home-area-of-practise {
  background-color: #dce2ed;
  padding: 40px 0;
}

.home-area-of-practise .areas-of-practise-header {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
}

.home-of-area-practise-box .box .home-area-of-practise-title {
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
  color: #fff;
  margin: 0;
}

.home-of-area-practise-box .box {
  background-color: #4a6276 !important;
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 10px 40px 10px;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.about-page p {
  font-family: "Roboto";
  font-size: 18px;
  text-align: justify;
}

.about-page .about-image {
  border-radius: 25px;
}

.about-page .about-headline {
  font-size: 28px;
  font-weight: 500;
}

@media all and (max-width: 480px) {
  .about-page .about-headline {
    padding-top: 8px;
  }

  .about-page p {
    text-align: left;
  }
}

/* Nav Bar Start */
.navbar-light .navbar-nav .nav-link {
  font-family: "Roboto" !important;
  font-size: 20px;
  color: #000 !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  -webkit-touch-callout: none;
}

.navbar-light .navbar-brand {
  font-family: "Roboto" !important;
  font-size: 24px;
  color: #000 !important;
  font-weight: 500;
}

.navbar-light {
  padding-right: 50px !important;
  padding-left: 50px !important;
  padding-top: 15px !important;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  -webkit-touch-callout: none;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  font-family: "Open Sans";
  font-weight: 400;
}

.dropdown-content a:hover {
  background-color: #ddd !important;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-contact {
  border: 2px solid #000;
  border-radius: 25px;
}
/* Nav Bar End */

@media only screen and (max-width: 768px) {
  .navbar-light .navbar-brand {
    font-size: 14px;
  }

  .navbar-light {
    padding-right: 15px !important ;
    padding-left: 15px !important ;
  }

  .nav-contact {
    border: 0px !important;
    border-radius: 25px;
  }
}

.personal-injury-head {
  background-image: url(/static/media/personal-injury.89924a73.png);
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}

p {
  font-family: "Open Sans";
  font-size: 17px;
}

.areas-of-practise-title {
  font-size: 24px;
  font-weight: 600;
}

.min-view {
  height: 100%;
}

.area-of-practise-header {
  max-width: 100%;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  margin-bottom: 2rem;
}

.area-of-practise-header-title {
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.area-of-practise-sidebar .sidebar-title {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 24px;
}

.area-of-practise-sidebar .sidebar-link {
  background-color: #4a6276;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 16px;
  font-family: "Roboto" !important;
}

.area-of-practise-sidebar a {
  color: #000;
  font-family: "Roboto" !important;
}

.wills-head {
  background-image: url(/static/media/wills.12f044c9.png);
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}

.probate-head {
  background-image: url(/static/media/probate.cb38999d.png);
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}

.conveyancing-head {
  background-image: url(/static/media/conveyancing.d9141388.png);
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}

.family-law-head {
  background-image: url(/static/media/family-law.329eaed8.png);
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}

.employment-head {
  background-image: url(/static/media/employment.2732d41c.png);
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}


.contact-form {
  padding: 90px 0;
}

.contact-form .map {
  border-radius: 25px;
}

.contact-form .form-control {
  border-radius: 10px !important;
  background-color: #f5f8fd !important;
  border: none;
}

.contact-form .btn-form {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #0095ff !important;
  border: none !important;
  border-radius: 25px !important;
  width: 180px;
  font-family: "Open Sans";
  font-size: 16px;
  text-align: center;
}

.contact-form .map-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 25px;
}

.contact-form .map-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media all and (max-width: 480px) {
  .min-view {
    height: 100%;
  }
}

