.footer {
  background-color: #171c26;
  padding: 40px 0;
  color: #fff;
}

.footer .social {
  width: 44px;
  margin-right: 8px;
}

.footer a {
  color: #0095ff;
  text-decoration: none !important;
}

.footer a:hover {
  color: #0095ff;
  text-decoration: none !important;
}

.footer .footer-title {
  font-weight: 600;
  font-size: 20px;
}

.footer .copyright {
  font-size: 15px;
}
