.area-of-practise-sidebar .sidebar-title {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 24px;
}

.area-of-practise-sidebar .sidebar-link {
  background-color: #4a6276;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 16px;
  font-family: "Roboto" !important;
}

.area-of-practise-sidebar a {
  color: #000;
  font-family: "Roboto" !important;
}
